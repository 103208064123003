import {ChevronRightIcon, PhotoIcon, UserCircleIcon} from '@heroicons/react/24/solid'
import {useState} from "react";
import Tags from "./Tags";
import FileUpload from "../component/form/FileUpload";
import axios, {AxiosResponse} from 'axios';


export class Ad {
    id: string;
    type: "image" | "video" | "html" | "vast";
    descriptor: string;
    keywords: string[];

    // Constructor
    constructor(id: string, type: "image" | "video" | "html" | "vast",descriptor: string,keywords: string[]) {
        this.id = id;
        this.type = type;
        this.descriptor = descriptor;
        this.keywords = keywords;
    }

}

export class AdType {
    id: "image" | "video" | "html" | "vast";
    title?: string;

    constructor(id: "image" | "video" | "html" | "vast") {
        this.id = id;
        switch (id) {
            case "html":
                this.title = "HTML";
                break;
            case "vast":
                this.title = "VAST";
                break;
            case "image":
                this.title = "Image";
                break;
            case "video":
                this.title = "Video";
                break;
        }
    }
}



async function getAdUploadURL(type: string) {
    try {

        const response: AxiosResponse = await axios.get('https://api.prismar.ai/ad/upload?type='+type,{ withCredentials: true });

        const responseData: any = response.data;
        console.log("responseData",responseData)

        return response.data

    } catch (error) {
        console.log(5)

        console.log("error",error)
        // Handle the error
        return null;
    }
}
//
async function fileUpload(file: any){
    let url: any = await getAdUploadURL(file.type)
    console.log(url)

    const config = {
        headers: {
            'content-type': file.type,
            'x-amz-acl':'public-read'
        }
    }
    return  axios.put(url.upload_url, file,config)
}

export function AdEdit() {
    const [imageFile, setImageFile] = useState<any>()

    const adTypes:AdType[] = [new AdType("image"),new AdType("video"),new AdType("vast"),new AdType("html")]

    const [adType, setAdType] = useState<string>('image')

    const [keywords, setKeywords] = useState<string[]  | undefined>(undefined)


    return (
        <>
            <div className="space-y-12 sm:space-y-16">
                <div>
                    <div className="md:flex md:items-center md:justify-between p-4">
                        <div className="min-w-0 ">
                            <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
                                       New Ad
                            </h2>
                        </div>

                    </div>
                    <nav className="hidden sm:flex px-4" aria-label="Breadcrumb">
                                     <ol role="list" className="flex items-center space-x-4">
                                         <li>
                                             <div className="flex">
                                                 <a href="#" className="text-sm font-medium text-gray-500 hover:text-gray-700">
                                                     Ads
                                                 </a>
                                             </div>
                                         </li>
                                         <li>
                                             <div className="flex items-center">
                                                 <ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                                                 <a href="#" className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">
                                                     New
                                                 </a>
                                             </div>
                                         </li>
                                     </ol>

                                 </nav>

                    <div className="mt-10 space-y-8 border-b border-gray-900/10 pb-12 sm:space-y-0 sm:divide-y sm:divide-gray-900/10 sm:border-t sm:pb-0">
                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                            <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                Ad Name
                            </label>
                            <div>
                                <input
                                    type="tags"
                                    name="tags"
                                    id="tags"
                                    className="block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    placeholder="Give your ad descriptive name"
                                />
                            </div>
                        </div>
                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                            <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                Ad Type
                            </label>
                            <div className="mt-2 sm:col-span-2 sm:mt-0">
                                <div className="space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
                                                        {adTypes.map((type) => (
                                                            <div key={type.id} className="flex items-center">
                                                                <input
                                                                    id={type.id}
                                                                    name="notification-method"
                                                                    type="radio"
                                                                    checked={type.id === adType}
                                                                    onChange={(e) => {console.log(e.target.id);setAdType(e.target.id)}}
                                                                    className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                                                />
                                                                <label htmlFor={type.id} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                                                                    {type.title}
                                                                </label>
                                                            </div>
                                                        ))}
                                                    </div>
                            </div>
                        </div>


                        {(adType === 'html' || adType === 'vast') && <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                            <label htmlFor="about" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                {adType.toUpperCase() + ' Tag'}
                            </label>
                            <div className="mt-2 sm:col-span-2 sm:mt-0">
                <textarea
                    id="code"
                    name="code"
                    rows={3}
                    className="block w-full max-w-2xl rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    defaultValue={''}
                    placeholder="Paste your ad tag here"
                />
                            </div>
                        </div>}


                        {(adType === 'video' || adType === 'image') && <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                            <label htmlFor="file" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                {adType.charAt(0).toUpperCase() + adType.slice(1) + ' File'}
                            </label>
                            <div className="mt-2 sm:col-span-2 sm:mt-0">
                                <FileUpload
                                    accept={['image/png','image/jpeg','image/gif']}
                                    limit='15k'
                                    onChange={(f:any) => setImageFile(f)}
                                />
                            </div>
                        </div>}
                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                            <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                Targeting Keywords
                            </label>
                            <div className="mt-2 sm:col-span-2 sm:mt-0">
                                  <Tags/>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className="mt-6 flex items-center justify-end gap-x-6 pr-4">
                <button type="button" className="text-sm font-semibold leading-6 text-gray-900 w-16">
                    Cancel
                </button>
                <button
                    onClick={async () => await fileUpload(imageFile)}
                    className=" w-32 inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                    Save
                </button>
            </div>
            </>
       )

}
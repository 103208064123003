import {PhotoIcon} from "@heroicons/react/24/solid";
import React, {useRef, useState} from "react";

var filesizeParser = require('filesize-parser');

interface FileUploadProps {
    accept?: string[];
    limit?: string;
    onChange?: any;
    onCancel?: any;
    title?: string;
}



export default function FileUpload(props: FileUploadProps) {

    const [file, setFile] = useState<any>()
    const inputRef =  useRef<HTMLInputElement | null>(null);
    const accept: string = props.accept ? props.accept.join(',') : '';
    const limit: number = props.limit ? filesizeParser(props.limit) : 0;

    function onChange(e: any) {
        setFile(e.target.files[0])
        if (props.onChange) {
             props.onChange(e.target.files[0]);
         }
    }


    function onCancel() {
        setFile(null)
        if (inputRef.current) {
            inputRef.current.value = ''
        }
        if (props.onCancel) {
            props.onCancel();
        }
    }



    // @ts-ignore
    // @ts-ignore
    // @ts-ignore
    // @ts-ignore
    // @ts-ignore
    // @ts-ignore
    return (
        <>
            {file &&
                <div className="flex gap-1 text-left text-sm font-bold mb-2 leading-5 text-black">
                    {file.name}
                    <div className="cursor-pointer text-cyan-600 underline font-normal" onClick={onCancel}> Remove</div>
                </div>
            }
        <div className="flex max-w-2xl justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
            <div className="text-center">
                <PhotoIcon className="mx-auto h-12 w-12 text-gray-300" aria-hidden="true" />
                <div className=" mt-4 flex text-sm leading-6 text-gray-600">
                    <label
                        htmlFor="file-upload"
                        className="relative cursor-pointer rounded-md bg-white font-semibold text-cyan-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-cyan-600 focus-within:ring-offset-2 hover:text-cyan-500"
                    >
                        <span>Upload a file</span>
                        <input id="file-upload"
                               ref={inputRef}
                               name="file-upload"
                               type="file"
                               className="sr-only"
                               accept={accept}
                               onChange={onChange}
                        />
                    </label>
                    <p className="pl-1">or drag and drop</p>
                </div>
                <p className="text-xs leading-5 text-gray-600">{props.title}</p>
            </div>
        </div>
        </>
    )

}
import React from 'react';
import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Login from "./Login";
import Home from "./Home";
import GoogleCallback from "./GoogleCallback";

function App() {
    return (
           <div className="App">
               <BrowserRouter>
                  <Routes>
                      <Route index element={<Login />} />
                      <Route path="/login" element={<Login/>}/>
                      <Route path="/auth/callback/google" element={<GoogleCallback/>}/>
                      <Route path="/home" element={<Home/>}/>
                  </Routes>
              </BrowserRouter>
          </div>
    );
}

export default App;
